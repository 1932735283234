import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// dichiaro stato
export interface loginState {
  email: string | null;
  user: string | null;
  isLoggedIn: boolean;
  expirationTime: number | null;
  bearerToken: string;
  authCode: boolean;
}
// valorizzo stato iniziale
const initialState: loginState = {
  email: null,
  user: null,
  isLoggedIn: false,
  expirationTime: null,
  bearerToken: "",
  authCode: false,
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setUser: (state, action: PayloadAction<string>) => {
      state.user = action.payload;
    },
    setExpirationTime: (state, action: PayloadAction<number>) => {
      state.expirationTime = action.payload;
    },
    setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
    },
    setBearerToken: (state, action: PayloadAction<string>) => {
      state.bearerToken = action.payload;
    },
    setAuthCode: (state, action: PayloadAction<boolean>) => {
      state.authCode = action.payload;
    },
    resetAllLogin: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const {
  setEmail,
  setUser,
  setExpirationTime,
  setIsLoggedIn,
  setBearerToken,
  setAuthCode,
  resetAllLogin,
} = loginSlice.actions;

export default loginSlice.reducer;
