import { GraphQLAuthMode } from "@aws-amplify/core/dist/esm/singleton/API/types";
import { appSyncEndpoint, appSyncApyKey } from "@/utils/constant";

export interface AmplifyConfig {
  API: {
    GraphQL: {
      endpoint: string;
      region: string;
      defaultAuthMode: GraphQLAuthMode;
      apiKey: string;
    };
  };
}

//NEW with env variables
export const config: AmplifyConfig = {
  API: {
    GraphQL: {
      endpoint: appSyncEndpoint,
      region: "eu-west-1",
      defaultAuthMode: "apiKey",
      apiKey: appSyncApyKey,
    },
  },
};

//old QA
//export const config: AmplifyConfig = {
//  API: {
//    GraphQL: {
//      endpoint:
//        "https://c2ihq62horf4rkiok5eje235qu.appsync-api.eu-west-1.amazonaws.com/graphql",
//      region: "eu-west-1",
//      defaultAuthMode: "apiKey",
//      apiKey: "da2-2qcqkkgrgzbaremu3zmoip7jj4",
//    },
//  },
//};

export const amplifyConfig = config;
