export const ADMIN_TOOL_SECTIONS = [
  "Tool Download",
  "Mapping Template",
  "Recipe Template",
  "Security",
];

export const cognitoUrlLogin = `${process.env.NEXT_PUBLIC_COGNITO_AUTH_URL}/authorize?response_type=code&client_id=${process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID}&redirect_uri=${process.env.NEXT_PUBLIC_REDIRECT_URI}&shallow=true`;
export const cognitoUrlLogout = `${process.env.NEXT_PUBLIC_COGNITO_LOGOUT_URL}?client_id=${process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID}&logout_uri=${process.env.NEXT_PUBLIC_COGNITO_LOGOUT_URL}?service=${process.env.NEXT_PUBLIC_API_BASE_URL}&service=${process.env.NEXT_PUBLIC_REDIRECT_URI}`;
export const host = process.env.NEXT_PUBLIC_API_BASE_URL || "";
export const baseUrl = `${host}/sdk/api/v1/fe`;
export const appSyncEndpoint = process.env.NEXT_PUBLIC_APPSYNC_ENDPOINT || "";
export const appSyncApyKey = process.env.NEXT_PUBLIC_APPSYNC_API_KEY || "";


