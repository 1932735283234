import { SearchResultData } from "@/types/search";
import { ApiError } from "@/types/types";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// dichiaro stato
export interface tableState {
  searchedValue: SearchResultData[] | null;
  error: ApiError | null;
  inputSearchValue: string;
}
// valorizzo stato iniziale
const initialState: tableState = {
  searchedValue: null,
  error: null,
  inputSearchValue: "",
};

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setSearch: (state, action: PayloadAction<SearchResultData[] | null>) => {
      state.searchedValue = action.payload;
    },
    resetSearch: (state) => {
      state.searchedValue = null;
    },
    setSearchError: (state, action: PayloadAction<ApiError | null>) => {
      state.error = action.payload;
    },
    setInputSearchValue: (state, action: PayloadAction<string>) => {
      state.inputSearchValue = action.payload.toUpperCase();
    },
    resetAllSearch: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const {
  setSearch,
  resetSearch,
  setSearchError,
  setInputSearchValue,
  resetAllSearch,
} = searchSlice.actions;

export default searchSlice.reducer;
