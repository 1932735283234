import { DownloadedSdk, HistoryList } from "@/types/history";
import { ApiError } from "@/types/types";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// dichiaro stato
export interface historyState {
  activeItem: DownloadedSdk | null;
  cube1Error: ApiError | null;
  cube2Error: ApiError | null;
  historyError: ApiError | null;
  searchedValue: HistoryList | null;
  selectedSdk: DownloadedSdk | null;
  idList: string[];
  deleteComplete: boolean;
  isInDownloadSbom: boolean;
}
// valorizzo stato iniziale
const initialState: historyState = {
  activeItem: null,
  cube1Error: null,
  cube2Error: null,
  historyError: null,
  searchedValue: null,
  selectedSdk: null,
  idList: [],
  deleteComplete: false,
  isInDownloadSbom: false,
};

export const historySlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setActiveItem: (state, action: PayloadAction<DownloadedSdk | null>) => {
      state.activeItem = action.payload;
    },
    setCube1Error: (state, action: PayloadAction<ApiError | null>) => {
      state.cube1Error = action.payload;
    },
    setCube2Error: (state, action: PayloadAction<ApiError | null>) => {
      state.cube2Error = action.payload;
    },
    setHistoryError: (state, action: PayloadAction<ApiError | null>) => {
      state.cube2Error = action.payload;
    },
    setHistorySearch: (state, action: PayloadAction<HistoryList | null>) => {
      state.searchedValue = action.payload;
    },
    setSelectedSdk: (state, action: PayloadAction<DownloadedSdk | null>) => {
      state.selectedSdk = action.payload;
    },
    setIdsList: (state, action: PayloadAction<string[]>) => {
      state.idList = action.payload;
    },
    setDeleteComplete: (state, action: PayloadAction<boolean>) => {
      state.deleteComplete = action.payload;
    },
    setIsInDownloadSbom: (state, action: PayloadAction<boolean>) => {
      state.isInDownloadSbom = action.payload;
    },
    resetAllHistory: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const {
  setActiveItem,
  setCube1Error,
  setCube2Error,
  setHistoryError,
  setHistorySearch,
  setSelectedSdk,
  setIdsList,
  setDeleteComplete,
  setIsInDownloadSbom,
  resetAllHistory,
} = historySlice.actions;

export default historySlice.reducer;
